import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import {ADVERTORIAL_BASE_URL} from '../constants'
import {formatAdvertorialGifs} from '../util/advertorialImages'
import Layout from '../components/layout'

import Advertorial from '../components/templates/sp-644-advertorial/advertorial'

const HealthSearch = () => {
    const images = useStaticQuery(graphql`
        query AdvertorialHealthSearch {
            hero: file(relativePath: {eq: "advertorial/health/health-hero.png"}) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            allFile(filter: {base: {glob: "*health*"}, extension: {eq: "gif"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
        }
    `)

    const seo = {
        title: `Shield Your Health and Medical Searches from 3rd Parties | Startpage`,
        description: `Beware non-private search engines are saving and selling personal health and medical search history. Learn how to protect your online privacy with Startpage.`,
        ogImagePath: `${ADVERTORIAL_BASE_URL}${images.hero.childImageSharp.fluid.src}`,
        ogUrl: `${ADVERTORIAL_BASE_URL}/health-search/`,
    }

    // see https://www.gatsbyjs.org/packages/gatsby-source-filesystem/ for querying / working with gifs in gatsby
    const sectionImages = formatAdvertorialGifs(images)

    const section1Text1 = `
        <p>If you think you’re the only one who knows about your online searches about health symptoms or medical treatment, think again! Over the last few years, an increasing number of companies are practicing search engine data health profiling and targeting. From annoying ads that follow you around the web, to profiled content, to pharmaceutical brands and insurance companies, your search history is being used to target you. As a consumer, it feels like someone is spying on your personal health searches and using it against you.</p>

        <p>So what should you do?</p>

        <p>Experts from all corners of the internet have been advising that people “start searching in private!”  Thankfully, the brilliant engineers from the Netherlands have found a solution with Startpage the world’s most private search engine.</p>
    `
    const section1Text2 = `
        <p>Using your search and browsing history, companies can identify your susceptibility in being concerned about a certain "ailment or medical prescription." According to Consumer Reports and Epic.org consumers need to protect themselves from being targeted with manipulative content, as well as medical scams and threats.</p>

        <p>Take the target off your back, protect the privacy of your health information, and start searching in private! Startpage is the world’s most private search engine that delivers Google search results through a proprietary anonymizing process.</p>
    `
    const section2Text1 = `
        <p>You can also view entire websites without leaving a trace or becoming a victim of health targeting by using Startpage’s ‘Anonymous View’ feature. Fast Company says, “This alternative search engine is basically Google without the privacy headaches.”</p>
    `
    const section3Text1 = `
        <p>Stop health and medical tracking. Start with Startpage!</p>

        <p>Do you know what the best part about Startpage is? It’s completely FREE! Try it out now.</p>
    `
    const bottomLinks = `
        <ul>
            <li>
                Consumer Reports:
                <a href="https://www.consumerreports.org/health-privacy/keep-your-health-information-private/">https://www.consumerreports.org/health-privacy/keep-your-health-information-private/</a>
            </li>
            <li>
                Electronic Privacy Information Center:
                <a href="https://epic.org/privacy/consumer/online-tracking/">https://epic.org/privacy/consumer/online-tracking/</a>
            </li>
        </ul>
    `

    const contentData = {
        sections: [
            {
                title: 'Why Privacy is Important When Searching for Health & Medical Information Online',
                image: images.hero.childImageSharp.fluid,
            },
            {
                title: 'Non-Private Search Engines Are Selling Your Search History and Digital Profile',
                text1: section1Text1,
                text2: section1Text2,
            },
            {
                title: 'How Private Search Works: No Health Tracking, No Cookies, No Targeting, No Search History',
                image: sectionImages[0],
                text1: section2Text1,
            },
            {
                title: 'Startpage’s Private Browsing Feature ‘Anonymous View’',
                image: sectionImages[1],
                text1: section3Text1,
            },
            {
                text1: bottomLinks,
            },
        ],
    }
    return (
        <Layout>
            <Advertorial content={contentData} seo={seo} />
        </Layout>
    )
}

export default HealthSearch
